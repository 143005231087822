@import "~normalize.css";

@font-face {
  font-family: "Lato";
  src: url("fonts/lato-regular.woff2") format("woff2"),
    url("fonts/lato-regular.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("fonts/montserrat-bold.woff2") format("woff2"),
    url("fonts/montserrat-bold.woff") format("woff");
}

html {
  font-size: 9px;
  font-family: "Lato";
}

@media screen and (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
